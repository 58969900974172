import { defineStore } from "pinia";
import { ref } from "vue";

export const useDialogStore = defineStore("dialogStore", () => {
  const discardChangesDialog = ref(false);

  const updateDiscardChangesDialog = (open) => {
    discardChangesDialog.value = open;
  };

  return {
    discardChangesDialog,
    updateDiscardChangesDialog,
  };
});
