<template>
  <v-app-bar app :elevation="2" rounded density="compact">
    <template v-slot:prepend>
      <v-app-bar-nav-icon>
        <v-icon @click="goBack">mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
    </template>
  </v-app-bar>
  <div
    data-testid="friend-request-verify-view"
    class="contentContainer"
    :style="{
      width: '100%',
      height: `${containerHeight}px`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      overflowY: 'auto',
    }"
  >
    <v-skeleton-loader
      v-if="friendRequestDocData == null"
      type="card"
      :style="{ width: `${isMobile ? '100%' : '350px'}` }"
    />
    <div v-else-if="friendRequestDocData == {}">
      <p>Friend request has expired</p>
    </div>
    <div v-else-if="friendRequestDocData.creatorName">
      <p style="font-size: larger">Friend request from</p>
      <p style="font-size: larger; font-weight: bold">
        {{ " " + friendRequestDocData.creatorName }}
      </p>
      <div style="margin-top: 50px">
        <v-btn
          data-testid="friend-request-verify-view-button-accept"
          variant="flat"
          @click="
            friendStore.replyFriendRequest(
              friendRequestDocRef,
              friendRequestDocData,
              true,
            )
          "
          color="success"
          :loading="friendRequestDocData == null"
          :disabled="friendStore.isProcessingResponse"
        >
          <v-icon v-if="isFriendRequestAccepted">mdi-check</v-icon>
          <span v-else> Accept </span>
          <template v-slot:loader>
            <v-progress-circular
              v-if="friendStore.isProcessingResponse"
              indeterminate
            ></v-progress-circular>
          </template>
        </v-btn>
      </div>
    </div>
    <div v-else>
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/authStore";
import { useDimenStore } from "@/stores/dimenStore";
import { useFriendStore } from "@/stores/friendStore";
import { useSnackBarStore } from "@/stores/snackBarStore";
import getFriendRequest from "@/utils/friendRequest/getFriendRequest";
import { nextTick, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const componentName = "friendRequestVerify";

export default {
  name: componentName,
  components: {},
  setup() {
    const authStore = useAuthStore();
    const snackBarStore = useSnackBarStore();
    const friendStore = useFriendStore();
    const dimenStore = useDimenStore();

    const router = useRouter();
    const route = useRoute();

    const friendRequestFound = ref(null); // Null when fetching, True if fetched & valid, False if fetched & invalid
    const isFriendRequestAccepted = ref(null); // Null by default, True if accepted, False if rejected
    const friendRequestDocRef = ref(null);
    const friendRequestDocData = ref(null); // Null by default, empty object ({}) if no document was found, a valid Firestore document when document found

    watch(
      friendRequestDocData,
      (newValue) => {
        console.log(
          componentName,
          "friendRequestDocData",
          "newValue:",
          newValue,
        );
        if (newValue?.creatorId != undefined) {
          // Friend request -document found
          if (newValue.creatorId == authStore.userId) {
            // Friend request created by the user itself; Return back /home
            snackBarStore.displayNotification({
              message: "Friend request not found",
              color: "error",
              timeout: 2000,
            });
            router.replace("/");
          }
        } else {
          // No document was found for the friend request code
          snackBarStore.displayNotification({
            message: "Friend request is expired",
            color: "error",
            timeout: 3000,
          });
          setTimeout(() => {
            router.replace("/");
          }, 2500);
        }
      },
      { immediate: false, deep: false },
    );

    const isMobile = ref(false);
    const containerHeight = ref(null);

    const goBack = () => {
      router.replace("/"); // Navigates back by one step in history
    };

    const getAppBarHeight = () => {
      console.info(componentName, "getAppBarHeight");
      try {
        const computedStyle = window.getComputedStyle(
          document.getElementsByClassName("v-toolbar")[0],
        );
        const appBarHeight = parseInt(computedStyle.height);
        console.debug(componentName, "appBarHeight: ", appBarHeight);
        dimenStore.setVBotNavHeight(appBarHeight);
        return appBarHeight;
      } catch (error) {
        console.error(componentName, "error:", error);
      }
    };

    const calculateContainerHeight = (appBarHeight) => {
      console.info(componentName, "calculateContainerHeight");
      const paddingVertical = 32; // Default Vue padding for v-container (2x16px)
      const browserInnerHeight = window.innerHeight;
      const _containerHeight =
        browserInnerHeight - (paddingVertical + appBarHeight);
      console.debug(
        componentName,
        "_contentContainerHeight: ",
        _containerHeight,
      );
      containerHeight.value = _containerHeight;
    };

    onMounted(async () => {
      console.debug(
        componentName,
        "friendStore.acceptedFriendRequest:",
        friendStore.acceptedFriendRequest,
      );
      // gOG7Gjku -->
      if (friendStore.acceptedFriendRequest) {
        snackBarStore.displayNotification({
          message: "Already have a friend",
          color: "error",
          timeout: 2500,
        });

        router.replace("/");
      }
      // <--

      const functionName = "onMounted";

      isMobile.value = window.innerWidth <= 600;

      const friendRequestCode = route.params.code;
      console.debug(
        componentName,
        functionName,
        "friendRequestCode: ",
        friendRequestCode,
      );

      if (!friendRequestCode || friendRequestCode?.length == 0) {
        console.debug(componentName, functionName, "No code provided in URL");
        router.replace("/home");
        return;
      }

      const result = await getFriendRequest(friendRequestCode);
      friendRequestDocRef.value = result.ref;
      friendRequestDocData.value = result.data;

      await nextTick();

      const appBarHeight = getAppBarHeight();
      calculateContainerHeight(appBarHeight);
    });

    return {
      friendStore,
      goBack,
      isMobile,
      containerHeight,
      friendRequestFound,
      isFriendRequestAccepted,
      friendRequestDocRef,
      friendRequestDocData,
    };
  },
};
</script>

<style scoped>
.v-btn {
  margin-right: 50px;
}

.v-btn:last-child {
  margin-right: 0px;
}
</style>
