<template>
  <v-app-bar app :elevation="2" rounded density="compact">
    <template v-slot:prepend>
      <v-app-bar-title style="font-weight: bold">Storyque</v-app-bar-title>
    </template>
  </v-app-bar>
  <div
    data-testid="auth-view"
    :style="{
      height: `${dimenStore.containerHeight}px`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
    }"
  >
    <div style="display: flex; width: 100%; flex-direction: column; gap: 15px">
      <v-text-field
        data-testid="register-username-input"
        :style="{
          width: '350px',
          height: '25px',
          alignSelf: 'center',
        }"
        :disabled="webauthnSupported == false"
        prepend-inner-icon="mdi-at"
        clearable
        label="Username"
        variant="outlined"
        v-model="usernameInput"
        :rules="[validateText]"
        type="text"
        @input="convertToLowercase"
        maxlength="15"
      ></v-text-field>
      <v-btn
        data-testid="register-button"
        variant="tonal"
        color="primary"
        :disabled="
          isRegisterDisabled ||
          webauthnSupported == false ||
          inputError.length > 0 ||
          processingRegistration
        "
        @click="register"
      >
        <v-icon
          style="margin-right: 10px"
          v-if="!processingRegistration"
          icon="mdi-account-plus"
        ></v-icon>
        {{ processingRegistration ? "" : "Register" }}
        <v-progress-circular
          v-if="processingRegistration"
          indeterminate
          size="20"
          width="3"
        ></v-progress-circular>
      </v-btn>
      <v-divider></v-divider>
      <p>Already have an account?</p>
      <v-btn
        data-testid="login-button"
        :disabled="webauthnSupported == false || processingLogin"
        variant="tonal"
        color="secondary"
        @click="login"
      >
        <v-icon
          style="margin-right: 10px"
          v-if="!processingLogin"
          icon="mdi-fingerprint"
        ></v-icon>
        {{ processingLogin ? "" : "Login" }}
        <v-progress-circular
          v-if="processingLogin"
          indeterminate
          size="20"
          width="3"
        ></v-progress-circular>
      </v-btn>
      <div
        v-if="webauthnSupported == false"
        data-testid="webauthn-disabled-text"
      >
        <p style="color: red; width: 300px; font-size: small">
          Device and/or browser does not support authentication with fingerprint
          reader or face recognition
        </p>
      </div>
    </div>
    <div
      style="
        position: absolute;
        bottom: 0px;
        width: 100%;
        padding: 5px;
        margin-top: 15px;
        margin-bottom: 15px;
      "
    >
      <v-btn
        variant="text"
        prepend-icon="mdi-email-arrow-left-outline"
        @click="
          () => {
            otpDialog = true;
          }
        "
        >Request One-Time-Password</v-btn
      >
    </div>
    <!-- </div> -->
    <v-dialog
      max-width="500"
      v-model="otpDialog"
      transition="dialog-fade-transition"
    >
      <template v-slot:default="">
        <v-card title="One Time Password">
          <v-tabs v-model="otpTab" fixed-tabs>
            <v-tab value="email" readonly>Email</v-tab>
            <v-tab value="otp" readonly>Code</v-tab>
            <v-tab value="registerNewDevice" readonly>New device</v-tab>
          </v-tabs>
          <v-card-text>
            <v-tabs-window v-model="otpTab">
              <v-tabs-window-item value="email">
                <v-text-field
                  v-model="emailInput"
                  width="95%"
                  clearable
                  label="Email"
                  prepend-icon="mdi-email-outline"
                  :rules="[validateEmail]"
                ></v-text-field>
                <div
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 5px;
                  "
                >
                  <v-btn
                    :disabled="
                      emailInput?.length == 0 ||
                      emailInputErrors?.length > 0 ||
                      processingEmail
                    "
                    @click="handleRequestOtp"
                    color="primary"
                    variant="tonal"
                  >
                    <v-progress-circular
                      v-if="processingEmail"
                      indeterminate
                      size="20"
                      width="3"
                    ></v-progress-circular>
                    <v-icon v-else icon="mdi-send"></v-icon>
                  </v-btn>
                </div>
              </v-tabs-window-item>
              <v-tabs-window-item value="otp"
                ><v-otp-input
                  v-model="otpInput"
                  :loading="processingOtp"
                  length="6"
                  variant="outlined"
                ></v-otp-input>
                <div
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 5px;
                  "
                >
                  <v-btn
                    :disabled="otpInput.length < 6 || processingOtp"
                    @click="handleOtpVerification"
                    color="primary"
                    variant="tonal"
                  >
                    <v-progress-circular
                      v-if="processingOtp"
                      indeterminate
                      size="20"
                      width="3"
                    ></v-progress-circular>
                    <v-icon v-else icon="mdi-check"></v-icon>
                  </v-btn>
                </div>
              </v-tabs-window-item>
              <v-tabs-window-item value="registerNewDevice">
                <v-text-field
                  v-model="deviceNameInput"
                  width="95%"
                  clearable
                  label="Device name"
                  prepend-icon="mdi-cellphone-link"
                  :rules="[validateDeviceNameInput]"
                />
                <div
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 5px;
                  "
                >
                  <v-btn
                    :disabled="
                      deviceNameInput.length < 6 || processingRegistration
                    "
                    @click="handleRegisterNewDevice"
                    color="primary"
                    variant="tonal"
                  >
                    <v-progress-circular
                      v-if="processingRegistration"
                      indeterminate
                      size="20"
                      width="3"
                    ></v-progress-circular>
                    <v-icon v-else icon="mdi-check"></v-icon>
                  </v-btn>
                </div>
              </v-tabs-window-item>
            </v-tabs-window>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useAuthStore } from "../stores/authStore";
import { auth } from "@/firebase";
import { useDimenStore } from "@/stores/dimenStore";
import { useSnackBarStore } from "@/stores/snackBarStore";
import webAuthnCreateCredential from "@/utils/access/webAuthnCreateCredential";
import { signInAnonymously } from "@firebase/auth";
import { onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import checkIfUsernameAvailable from "../utils/access/checkIfUsernameAvailable";
import getChallenge from "@/utils/access/getChallenge";
import webAuthnLogin from "../utils/access/webAuthnLogin";
import webAuthSendCredential from "../utils/access/webAuthnSendCredential";
import getDeviceInfo from "@/utils/linkDevices/getDeviceInfo";
import linkNewDeviceToUser from "@/utils/linkDevices/linkNewDeviceToUser";
import requestOtp from "../utils/access/requestOtp";
import verifyOtp from "../utils/access/verifyOtp";

const getChallengeFunction =
  window.moduleOverrides?.getChallenge ?? getChallenge;

const webAuthnLoginFunction =
  window.moduleOverrides?.webAuthnLogin ?? webAuthnLogin;

const webAuthSendCredentialFunction =
  window.moduleOverrides?.webAuthSendCredential ?? webAuthSendCredential;

const webAuthnCreateCredentialFunction =
  window.moduleOverrides?.webAuthnCreateCredential ?? webAuthnCreateCredential;

const signInAnonymouslyFunction =
  window.moduleOverrides?.signInAnonymously ?? signInAnonymously;

const checkIfUsernameAvailableFunction =
  window.moduleOverrides?.checkIfUsernameAvailable ?? checkIfUsernameAvailable;

const componentName = "AuthView";

const route = useRoute();
const router = useRouter();
const dimenStore = useDimenStore();
const snackBarStore = useSnackBarStore();
const usernameInput = ref("");
const authStore = useAuthStore();
const webauthnSupported = ref(window.PublicKeyCredential);
const inputError = ref([]);

const processingLogin = ref(false);

watch(
  () => authStore.userId,
  (newUserId) => {
    if (newUserId) {
      const redirect = route.query.redirect || "/home";
      router.replace(redirect);
    }
  },
  { immediate: false, deep: false },
);

/**
 * xqxuuUvs -->
 *
 * Usual use case is after unlinking all devices for an user account, after which user
 * needs an OTP to access their user account again. Then user can link their current device
 * to use WebAuthn for future logins.
 */

const resetDialog = () => {
  otpDialog.value = false;
  otpTab.value = "email";
  emailInput.value = "";
  otpInput.value = "";
};

const validateEmail = (value) => {
  const functionName = "validateEmail";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "value:", value);

  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  emailInputErrors.value = re.test(value.toLowerCase())
    ? []
    : ["Invalid email address"];
  console.debug(
    componentName,
    functionName,
    "regex result:",
    emailInputErrors.value,
  );

  if (emailInputErrors.value.length > 0) {
    return emailInputErrors.value[0];
  } else {
    return true;
  }
};

/**
 * "New" device registration handling after unlinking all devices:
 *
 * 1. Get OTP into (verified) email
 * 2. Verify OTP after user input the code received in email (receive userId and username)
 * 3. Get challenge for WebAuthn
 * 4. Create new WebAuthn credential for the device with challenge, userId and username
 * 5. Link device to user by creating a Firestore document that contains WebAuthn credential and userId
 * 6. On successful device registration (Firestore document stored without errors), log in user and take them to home view
 */

// v-dialog handling
const otpDialog = ref(null);
const otpTab = ref("email");

// email
const emailInputErrors = ref([]);
const emailInput = ref("");
const processingEmail = ref(false);

// otp
const otpInput = ref("");
const processingOtp = ref(false);
let userId, username;

// device linking
const deviceNameInput = ref("");
const processingRegistration = ref(false);

const handleRequestOtp = async () => {
  const functionName = "handleRequestOtp";
  console.info(componentName, functionName);

  processingEmail.value = true;

  const response = await requestOtp(emailInput.value);
  if (response.status == 200) {
    otpTab.value = "otp";
  } else {
    resetDialog();

    snackBarStore.displayNotification({
      message:
        response.status == 404
          ? response.statusText
          : "Error occurred, please try again later",
      color: "error",
      timeout: 2000,
    });
  }

  processingEmail.value = false;
};

const handleOtpVerification = async () => {
  const functionName = "handleOtpVerification";
  console.info(functionName);
  console.debug(functionName, `otpInput: ${otpInput.value}`);

  const response = await verifyOtp(emailInput.value, otpInput.value);
  if (response.status == 200) {
    userId = response.data.userId;
    username = response.data.username;
    otpTab.value = "registerNewDevice";
  } else {
    resetDialog();

    snackBarStore.displayNotification({
      message:
        response.status == 404
          ? response.statusText
          : "Error occurred, please try again later",
      color: "error",
      timeout: 2000,
    });
  }
};

const validateDeviceNameInput = (value) => {
  const functionName = "validateDeviceNameInput";

  if (!value || value.trim().length == 0) {
    inputError.value = [];
    return true;
  }

  let errorMessage = "";

  let regex = /^[A-Za-z]/; // Allow only letters as first character
  errorMessage = "First character has to be a letter";
  if (!regex.test(value)) {
    if (inputError.value.indexOf(errorMessage) === -1)
      inputError.value.push(errorMessage);
  } else if (inputError.value.indexOf(errorMessage) !== -1) {
    inputError.value.splice(inputError.value.indexOf(errorMessage), 1);
  }

  regex = /^.{15,50}$/; // Device name has to be specific length
  errorMessage = "Device name has to be 15-50 characters long";
  if (!regex.test(value)) {
    if (inputError.value.indexOf(errorMessage) === -1)
      inputError.value.push(errorMessage);
  } else if (inputError.value.indexOf(errorMessage) !== -1) {
    inputError.value.splice(inputError.value.indexOf(errorMessage), 1);
  }

  console.debug(componentName, functionName, "inputError: ", inputError.value);

  return inputError.value.length > 0 ? inputError.value[0] : true;
};

/**
 * Uses same principle for linking a new device to an user account as when registering a new user account:
 *
 * 1. Create WebAuthn credential/registration-object for the currently in-use device
 * 2. Create Firestore document that contains before mentioned credential-object and userId
 * 3. On next login use the Firestore document to check that the credential-object matches the one sent to the server
 */
const handleRegisterNewDevice = async () => {
  const functionName = "handleRegisterNewDevice";
  console.info(functionName);

  let challenge, registration, isLinkingSuccessful;

  const response = await getChallengeFunction();
  challenge = response.challenge;

  if (challenge) {
    registration = await webAuthnCreateCredentialFunction(
      challenge,
      userId,
      username,
    );
  }

  if (registration) {
    isLinkingSuccessful = await linkNewDeviceToUser(
      userId,
      registration.registration.credential,
      getDeviceInfo(),
      deviceNameInput.value,
    );
  }

  if (isLinkingSuccessful) {
    try {
      // On successful linking, log user in and take them to the home view
      await signInAnonymouslyFunction(auth);

      if (userId && username) {
        authStore.setUserId(userId);
        authStore.setUsername(username);
      } else {
        console.error(
          componentName,
          functionName,
          `userId: '${userId}', username: '${username}'`,
        );
      }

      snackBarStore.displayNotification({
        message: "New device linked",
        color: "success",
        timeout: 2000,
      });
      router.replace({ name: "profile" });
    } catch (error) {
      console.error(componentName, functionName, "Error: ", error);
      snackBarStore.displayNotification({
        message: "Error occurred when linking new device",
        color: "error",
        timeout: 2000,
      });
    }
  }
};
// <-- xqxuuUvs

async function register() {
  const functionName = "register";
  /**
   * - Verify that username is available
   * - Request challenge (registering new User ID) via API
   * - Generate public/private key pair
   * - Register new user in backend via API with keypair and User ID
   * - Request new challenge
   * - Login existing user with signature which is verified with public key in backend
   * - Login anonymously via Firebase
   */
  let available, challenge, credential, registerResponse;

  processingRegistration.value = true;

  try {
    available = await checkIfUsernameAvailableFunction(usernameInput);
    console.debug(componentName, functionName, "available:", available);
    if (!available) {
      snackBarStore.displayNotification({
        message: "Username unavailable",
        color: "error",
      });

      processingRegistration.value = false;
      return;
    }

    const response = await getChallengeFunction();
    challenge = response.challenge;

    if (challenge) {
      console.debug(componentName, functionName, "challenge:", challenge);
      credential = await webAuthnCreateCredentialFunction(
        challenge,
        usernameInput.value,
      );

      console.debug(componentName, functionName, "credential:", credential);

      // Either WebAuthn isn't supported on device or there was backend error
      if (credential.status == false) {
        snackBarStore.displayNotification({
          message: credential.message,
          color: "error",
        });

        processingRegistration.value = false;
        return;
      }
    }

    registerResponse = await webAuthSendCredentialFunction(
      challenge,
      credential,
      usernameInput.value,
    );

    console.debug(
      componentName,
      functionName,
      "registerResponse:",
      JSON.stringify(registerResponse),
    );

    if (registerResponse == null) {
      console.error(componentName, functionName, "Failed to register user");
      snackBarStore.displayNotification({
        message: "Registration failed",
        color: "error",
      });

      processingRegistration.value = false;
      return;
    }

    console.debug(componentName, functionName, "User registered successfully");

    try {
      await signInAnonymouslyFunction(auth);

      authStore.userId = registerResponse.userId;
      authStore.username = registerResponse.username;

      snackBarStore.displayNotification({
        message: "Registration successful",
        color: "success",
      });
      router.replace("/");
    } catch (error) {
      console.error(componentName, functionName, "Error: ", error);
      return;
    } finally {
      processingRegistration.value = false;
    }
  } catch (error) {
    console.error(error);
  }
}

const login = async () => {
  const functionName = "login";
  console.info(componentName, functionName);

  processingLogin.value = true;

  let challenge, loginResponse;
  /**
   * - Request challenge
   * - Login existing user with signature which is verified with public key in backend
   * - Login anonymously using Firebase
   */

  try {
    const response = await getChallengeFunction();
    challenge = response.challenge;
    if (!challenge) {
      processingLogin.value = false;
      return;
    }

    loginResponse = await webAuthnLoginFunction(challenge);

    if (!loginResponse.status) {
      console.error(componentName, functionName, "login", "Failed to log in");
      snackBarStore.displayNotification({
        message: loginResponse.message,
        color: "error",
      });

      processingLogin.value = false;
      return;
    }

    await signInAnonymouslyFunction(auth);
    console.debug(
      componentName,
      functionName,
      "Successfully logged in anonymously via Firebase",
    );
    authStore.setUserId(loginResponse.userId);
    authStore.setUsername(loginResponse.username);

    snackBarStore.displayNotification({
      message: "Login successful",
      color: "success",
    });
    processingLogin.value = false;
    router.replace("profile");
  } catch (error) {
    processingLogin.value = false;
    console.error(componentName, functionName, error);
  }
};

const validateText = (value) => {
  const functionName = "validateText";

  if (value.trim().length == 0) {
    inputError.value = [];
    return true;
  }

  let errorMessage = "";

  let regex = /^[a-z]/; // Allow only letters (both upper and lower case) and numbers
  errorMessage = "First character has to be a letter";
  if (!regex.test(value)) {
    if (inputError.value.indexOf(errorMessage) === -1)
      inputError.value.push(errorMessage);
  } else if (inputError.value.indexOf(errorMessage) !== -1) {
    inputError.value.splice(inputError.value.indexOf(errorMessage), 1);
  }

  regex = /^.{5,15}$/; // Username has to be between 5-15 characters long
  errorMessage = "Username has to be between 5-15 characters long";
  if (!regex.test(value)) {
    if (inputError.value.indexOf(errorMessage) === -1)
      inputError.value.push(errorMessage);
  } else if (inputError.value.indexOf(errorMessage) !== -1) {
    inputError.value.splice(inputError.value.indexOf(errorMessage), 1);
  }

  console.debug(componentName, functionName, "inputError: ", inputError.value);

  return inputError.value.length > 0 ? inputError.value[0] : true;
};

const convertToLowercase = () => {
  usernameInput.value = usernameInput.value.toLowerCase();
};

onMounted(async () => {
  const functionName = "onMounted";
  console.info(componentName, functionName);

  if (window.automatedTesting) {
    window.$webauthnSupported = webauthnSupported;
    window.$processingRegistration = processingRegistration;
    window.register = register;
    window.signInAnonymously = signInAnonymously;
  }
});

const isRegisterDisabled = computed(() => {
  return (
    usernameInput.value.length < 5 ||
    usernameInput.value.length > 15 ||
    inputError.value.length != 0
  );
});
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.v-btn {
  align-self: center;
  margin-top: 20px;
  max-width: 50%;
}
</style>
