<template>
  <v-overlay
    persistent
    v-model="loadingScreenStore.isLoading"
    style="background-color: #ffffff"
  >
    <div class="overlay-content">
      <!-- Your centered content goes here -->
      <h1 style="margin-bottom: 25px">Storyque</h1>
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
  </v-overlay>
</template>

<script>
import { useLoadingScreenStore } from "@/stores/loadingScreenStore";
import { useAuthStore } from "@/stores/authStore";
import { useNsfwStore } from "@/stores/nsfwStore";
import { watch, onMounted } from "vue";

const componentName = "LoadingScreen";

export default {
  name: componentName,
  components: {},
  setup() {
    const loadingScreenStore = useLoadingScreenStore();
    const authStore = useAuthStore();
    const nsfwStore = useNsfwStore();

    onMounted(() => {
      // Wait for user authentication and notification permission checking to complete before taking user to app
      watch(
        () => [authStore.initialized, nsfwStore.initialized],
        ([authStoreInitialized, nsfwStoreInitialized]) => {
          console.log(
            componentName,
            "authStoreInitialized:",
            authStoreInitialized,
            "nsfwStoreInitialized:",
            nsfwStoreInitialized,
          );
          if (authStoreInitialized && nsfwStoreInitialized) {
            setTimeout(() => {
              loadingScreenStore.isLoading = false;
            }, 1500);
          }
        },
        { immediate: true, deep: false },
      );
    });

    return { loadingScreenStore };
  },
};
</script>

<style scoped>
.overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Ensures the content takes the full height of the overlay */
  width: 100vw;
}
</style>
