import { defineStore } from "pinia";
import { ref } from "vue";
import { openDB } from "idb";

const storeName = "notificationStore";

const NOTIFICATIONS_DB_NAME = "notificationsDB";
const NOTIFICATIONS_DB_VERSION = 1;
const NOTIFICATIONS_STORE_NAME = "notificationsStore";

/**
 * This store handles storing all notifications until user has interacted with them, helping them not to miss a notification
 */

export const useNotificationStore = defineStore({
  id: storeName,
  state: () => ({
    notificationPermission: ref(Notification.permission),
    notifications: ref([]),
    db: null,
  }),
  actions: {
    async onInit() {
      const functionName = "onInit";
      console.info(storeName, functionName);

      await this.readNotificationsFromIDB();
    },
    async openDB() {
      const functionName = "openDB";
      console.info(storeName, functionName);

      if (!this.db) {
        this.db = await openDB(
          NOTIFICATIONS_DB_NAME,
          NOTIFICATIONS_DB_VERSION,
          {
            upgrade(db) {
              db.createObjectStore(NOTIFICATIONS_STORE_NAME, {
                keyPath: "id",
                autoIncrement: false,
              });
            },
          },
        );
      }
    },
    /**
     * For system related notifications like requesting permission for notifications, location etc.
     * @param {*} notification - object containing notificationType-string to display corresponding popup/dialog
     */
    saveNotification(notification) {
      const functionName = "saveNotification";
      console.info(storeName, functionName);

      if (!notification.notificationType) {
        console.error(
          storeName,
          "invalid notification type:",
          notification.notificationType,
        );
        return;
      }

      if (this.checkIfNotificationTypeExist(notification.notificationType)) {
        return;
      }

      console.debug(
        storeName,
        functionName,
        `notifications count before saving: '${this.notifications.length}'`,
      );
      this.notifications.push(notification);
      console.debug(
        storeName,
        functionName,
        `notifications count after saving: '${this.notifications.length}'`,
      );
    },
    checkIfNotificationTypeExist(notificationType) {
      const functionName = "checkIfNotificationTypeExist";
      // console.info(storeName, functionName);

      const index = this.notifications.findIndex(
        (notification) => notification?.notificationType === notificationType,
      );

      if (index > -1) {
        console.debug(
          storeName,
          functionName,
          `notification with type '${notificationType}' already exist`,
        );
        return true;
      } else {
        // console.debug(
        //   storeName,
        //   functionName,
        //   `no notification with type '${notificationType}' exist`,
        // );
        return false;
      }
    },
    /**
     * Removes notification created by the app itself, e.g. for requesting a permission
     * @param {*} notificationType
     */
    async removeNotification(notificationType) {
      const functionName = "removeNotification";
      console.info(storeName, functionName);

      const index = this.notifications.findIndex(
        (notification) => notification?.notificationType === notificationType,
      );

      if (index > -1) {
        this.notifications.splice(index, 1);

        console.debug(
          storeName,
          functionName,
          `app generated notification with type: '${notificationType}' removed at index '${index}'`,
        );
      } else {
        console.debug(
          storeName,
          functionName,
          `app generated notification with type: '${notificationType}' not found`,
        );
      }
    },
    /**
     * Removes notification with identifier from both Pinia store and IndexedDB
     * @param {*} notificationId
     */
    async removeIdNotification(notificationId) {
      const functionName = "removeIdNotification";
      console.info(storeName, functionName);
      console.debug(
        storeName,
        functionName,
        `notificationId: '${notificationId}'`,
      );

      const index = this.getNotificationIndexById(notificationId);
      if (index > -1) {
        this.notifications.splice(index, 1);
        try {
          await this.removeNotificationByIdFromIDB(notificationId);
          console.debug(
            storeName,
            functionName,
            `notification with id: '${notificationId}' deleted from IDB`,
          );
        } catch (error) {
          console.error(storeName, functionName, error);
        }
      } else {
        console.debug(
          storeName,
          functionName,
          `notification with id: '${notificationId}' not found`,
        );
      }
    },
    async removeNotificationByIdFromIDB(notificationId) {
      const functionName = "removeNotificationByIdFromIDB";
      console.info(storeName, functionName);
      console.debug(
        storeName,
        functionName,
        `notificationId: ${notificationId}`,
      );

      await this.openDB();

      try {
        await this.db.delete(NOTIFICATIONS_STORE_NAME, notificationId);
      } catch (error) {
        console.error(storeName, functionName, error);
      }
    },
    async readNotificationsFromIDB() {
      const functionName = "readNotificationFromIDB";
      console.info(storeName, functionName);

      await this.openDB();

      const allNotifications = await this.db.getAll(NOTIFICATIONS_STORE_NAME);

      // Filter out duplicates
      const uniqueNotifications = allNotifications.filter(
        (notification) =>
          !this.notifications.some(
            (existingNotification) =>
              existingNotification.id === notification.id,
          ),
      );

      this.notifications = [...this.notifications, ...uniqueNotifications];
    },
    findNotificationById(id) {
      const functionName = "findNotificationById";
      console.info(functionName);

      return this.notifications.find((notification) => notification.id === id);
    },
    getNotificationIndexById(notificationId) {
      const functionName = "getNotificationIndexById";
      console.info(storeName, functionName);

      const index = this.notifications.findIndex(
        (notification) => notification.id === notificationId,
      );
      if (index > -1) {
        console.debug(
          storeName,
          functionName,
          `notification's index is: '${index}'`,
        );
      } else {
        console.debug(
          storeName,
          functionName,
          `couldn't find index for notification with id: '${notificationId}'`,
        );
      }

      return index;
    },
  },
});
