<template>
  <v-app-bar app :elevation="2" rounded density="compact">
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click="goBack" data-testid="back-button">
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title style="font-weight: bold">Storyque</v-app-bar-title>
    </template>
    <template v-slot:append>
      <v-app-bar-nav-icon
        data-testid="post-or-update-button"
        color="primary"
        :disabled="isStoryPostable == false"
        @click="
          async () => {
            if (route.name == 'storyEditor') {
              await editStoryStore.updateStory();
              goBack();
            } else {
              await newStoryStore.postStory();
            }
          }
        "
      >
        <v-icon
          v-if="
            isEditMode
              ? editStoryStore.isPosting == false
              : newStoryStore.posting == false
          "
          >{{
            route.name == "storyEditor" ? "mdi-content-save-edit" : "mdi-send"
          }}</v-icon
        >
        <v-progress-circular v-else color="primary" indeterminate />
      </v-app-bar-nav-icon>
    </template>
  </v-app-bar>
  <div
    data-testid="story-creator-view"
    class="d-flex justify-center"
    :style="{
      height: `${dimenStore.containerHeight}px`,
      width: '100%',
      overflowX: 'hidden',
      overflowY: 'auto',
      boxSizing: 'border-box',
    }"
  >
    <v-skeleton-loader card v-if="storyPagesLoaded"> </v-skeleton-loader>
    <Sortable
      v-else
      :list="isEditMode ? editStoryStore.pages : newStoryStore.pages"
      item-key="id"
      tag="div"
      :options="options"
      @end="handleStoryPageDragNDrop"
    >
      <template #item="{ element, index }">
        <div
          :key="element.id"
          :style="{
            width: isMobile ? '100%' : '450px',
            maxWidth: '450px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'whitesmoke',
            padding: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            marginTop: '5px',
            marginBottom: '5px',
          }"
        >
          <div style="margin-bottom: auto">
            <v-icon class="my-handle-class" style="margin-right: 10px"
              >mdi-menu</v-icon
            >
          </div>
          <div
            :class="`panel panel-${element.id}`"
            :style="{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: `${element.isExpanded ? '999px' : '100px'}`,
              overflowY: 'hidden',
              transition: 'max-height .5s',
            }"
            @transitionend="handleTransitionEnd(element.id)"
          >
            <div style="display: flex; flex-direction: row; padding: 5px">
              <div
                :data-testid="`story-media-img-button#${index}`"
                style="
                  padding-left: 5px;
                  padding-right: 5px;
                  margin-bottom: 5px;
                "
                @click="
                  () => {
                    handleBottomSheet(element.isExpanded, element.id);
                  }
                "
              >
                <v-img
                  v-if="element.media.url"
                  :data-testid="`story-media-img#${index}`"
                  width="200px"
                  max-width="200px"
                  height="200px"
                  max-height="200px"
                  :src="element.media.url"
                  @error="
                    isEditMode
                      ? editStoryStore.setUrlValidity(element.id, false)
                      : newStoryStore.setUrlValidity(element.id, false)
                  "
                  @load="
                    isEditMode
                      ? editStoryStore.setUrlValidity(element.id, true)
                      : newStoryStore.setUrlValidity(element.id, true)
                  "
                />
                <v-sheet
                  v-else-if="
                    element.media.url == '' || element.media.valid == false
                  "
                  :height="200"
                  :width="200"
                  rounded
                  color="grey"
                  class="d-flex justify-center align-center"
                >
                  <v-icon icon="mdi-image-area"></v-icon
                ></v-sheet>
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <v-btn
                  :data-testid="`story-page-expand-button#${index}`"
                  :icon="
                    element.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
                  "
                  @click="
                    () => {
                      if (isEditMode) {
                        editStoryStore.toggleStoryPageExpanded(element.id);
                      } else {
                        newStoryStore.toggleStoryPageExpanded(element.id);
                      }
                    }
                  "
                  style="padding: 5px; margin-bottom: 5px"
                  variant="text"
                ></v-btn>
                <v-btn
                  v-if="!isEditMode"
                  :data-testid="`story-page-delete#${index}`"
                  color="error"
                  :disabled="newStoryStore.pages.length === 1"
                  @click="newStoryStore.deleteStoryPage(element.id)"
                  style="margin: 5px"
                  icon="mdi-trash-can-outline"
                  ripple
                  variant="outlined"
                >
                </v-btn>
              </div>
            </div>
            <v-textarea
              :data-testid="`story-text-input-field#${index}`"
              placeholder="One beautiful day.."
              :rules="[validateStoryPageText]"
              clearable
              persistent-clear
              @click:clear="
                isEditMode
                  ? editStoryStore.resetStoryPageText(element.id)
                  : newStoryStore.resetStoryPageText(element.id)
              "
              label="Story text"
              variant="outlined"
              counter
              maxlength="1000"
              v-model="element.text"
            ></v-textarea>
          </div>
        </div>
      </template>
    </Sortable>
  </div>
  <div
    style="
      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      bottom: 0px;
      margin-bottom: 5px;
      right: 0px;
      padding: 10px;
      flex-shrink: 0;
      gap: 10px;
    "
  >
    <v-speed-dial
      location="top center"
      transition="fade-transition"
      v-model="fabIsOpen"
    >
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn
          data-testid="story-shared-speed-dial"
          v-bind="activatorProps"
          size="large"
          :icon="speedDialButtons[sharedStatus].icon"
          elevation="2"
        ></v-btn>
      </template>

      <v-btn
        v-for="(speedDialButton, index) in speedDialButtons"
        :data-testid="`story-shared-status-button-${speedDialButton.sharedStatusName}`"
        :key="speedDialButton.id"
        :style="{
          backgroundColor: sharedStatus == index ? primaryColor : '',
        }"
        :disabled="speedDialButton.disabled"
        @click="speedDialButton.action"
        :icon="speedDialButton.icon"
        :value="index"
      />
    </v-speed-dial>
    <v-btn
      v-if="!isEditMode"
      size="large"
      :disabled="storyPagesCount.length == 5"
      icon
      color="secondary"
      class="rounded-btn"
      ripple
      elevation="2"
      @click="
        () => {
          newStoryStore.addStoryPage();
          scrollToLastStoryPage();
        }
      "
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
  <v-bottom-sheet
    data-testid="storyCreatorView-bottom-sheet"
    v-model="isBottomSheetVisible.open"
    @input="handlebottomSheetInput"
  >
    <v-sheet style="padding: 5px">
      <p style="padding: 5px">Select media from..</p>
      <v-btn
        v-for="(button, index) in bottomSheetOptions"
        :data-testid="`bottom-sheet-action#${index}`"
        :key="button.id"
        @click="button.action"
        :variant="button.variant"
        :color="button.color"
        style="width: 100%"
      >
        {{ button.label }}
      </v-btn>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script setup>
import { useDimenStore } from "@/stores/dimenStore";
import { useNewStoryStore } from "../stores/newStoryStore";

import { onMounted, onUnmounted, computed, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import { Sortable } from "sortablejs-vue3";

import { useFriendStore } from "@/stores/friendStore";
import { useTheme } from "vuetify";

import { useEditStoryStore } from "@/stores/editStoryStore";
import { useMyStoriesStore } from "@/stores/myStoriesStore";
import { useSnackBarStore } from "@/stores/snackBarStore";
import { handleRouting } from "@/utils/routing/handleRouting";

const componentName = "StoryCreatorView";

const route = useRoute();

const editStoryStore = useEditStoryStore();
const isEditMode = ref(route.params?.postId !== undefined);

if (isEditMode.value) {
  editStoryStore.populateStoryToBeEdited(route.params.postId);
}

const dimenStore = useDimenStore();
const router = useRouter();
const newStoryStore = useNewStoryStore();
const isMobile = ref(null);
const isBottomSheetVisible = ref({ open: false, targetStoryPageId: null });

watch(
  () => isBottomSheetVisible.value,
  (newIsBottomSheetVisible) => {
    Object.keys(newIsBottomSheetVisible).forEach((key) => {
      console.log(
        componentName,
        `newIsBottomSheetVisible, key: ${key}, value: ${newIsBottomSheetVisible[key]}`,
      );
    });
  },
  { immediate: false, deep: false },
);

const friendStore = useFriendStore();
const bottomSheetOptions = [
  {
    id: 0,
    label: "Paste link",
    color: "black",
    variant: "outline",
    action: async () => {
      await pasteFromClipboard(isBottomSheetVisible.value.targetStoryPageId);
      isBottomSheetVisible.value = {
        open: false,
        targetStoryPageId: null,
      };
    },
  },
  {
    id: 1,
    label: "Reset",
    color: "error",
    variant: "text",
    action: () => {
      newStoryStore.resetStoryPageMedia(
        isBottomSheetVisible.value.targetStoryPageId,
      );
      isBottomSheetVisible.value = {
        open: false,
        targetStoryPageId: null,
      };
    },
  },
];

const myStoriesStore = useMyStoriesStore();
const snackBarStore = useSnackBarStore();

const sharedStatus = computed(() => {
  return isEditMode.value
    ? editStoryStore.sharedStatus
    : newStoryStore.sharedStatus;
});

console.debug(componentName, "sharedStatus:", editStoryStore.sharedStatus);

// v-speed-dial -->
const fabIsOpen = ref(false);
const vTheme = useTheme();
const primaryColor = vTheme.current.value.colors.primary + "BF";
const speedDialButtons = ref([
  {
    sharedStatusName: "private",
    id: "speed-dial-button-0",
    icon: "mdi-lock",
    style: {
      backgroundColor: sharedStatus.value == 0 ? primaryColor : "",
    },
    action: () => {
      if (isEditMode.value) {
        editStoryStore.sharedStatus = 0;
      } else {
        newStoryStore.sharedStatus = 0;
      }
    },
    disabled: false,
  },
  {
    sharedStatusName: "public",
    id: "speed-dial-button-1",
    icon: "mdi-earth",
    style: {
      backgroundColor: sharedStatus.value == 1 ? primaryColor : "",
    },
    action: () => {
      if (isEditMode.value) {
        editStoryStore.sharedStatus = 1;
      } else {
        newStoryStore.sharedStatus = 1;
      }
    },
    disabled: false,
  },
  {
    sharedStatusName: "privatelyShared",
    id: "speed-dial-button-2",
    icon: "mdi-account-multiple",
    action: () => {
      if (isEditMode.value) {
        editStoryStore.sharedStatus = 2;
      } else {
        newStoryStore.sharedStatus = 2;
      }
    },
    disabled: true,
  },
]);
// <--

watch(
  () => friendStore.acceptedFriendRequest,
  (newAcceptedFriendRequest) => {
    console.debug("newAcceptedFriendRequestt:", newAcceptedFriendRequest);
    if (newAcceptedFriendRequest) {
      console.debug("privatelyShared-button set to ENABLED");
      speedDialButtons.value[2].disabled = false;
    } else {
      console.debug("privatelyShared-button set to DISABLED");
      speedDialButtons.value[2].disabled = true;
    }
  },
  { immediate: true, deep: true },
);

const goBack = () => {
  handleRouting("/", router, null);
};

const handleBottomSheet = async (isExpanded, storyPageId) => {
  const functionName = "handleBottomSheet";
  console.info(componentName, functionName);
  console.debug(
    componentName,
    functionName,
    "isExpanded:",
    isExpanded,
    "storyPageId:",
    storyPageId,
  );

  if (!isExpanded) return;

  isBottomSheetVisible.value = {
    open: true,
    targetStoryPageId: storyPageId,
  };
};

// Handle closing BottomSheet, nullifying the temporary storing of selected story page's ID
const handlebottomSheetInput = (isOpen) => {
  const functionName = "handlebottomSheetInput";
  console.info(componentName, functionName);
  console.debug(componentName, functionName, "isOpen:", isOpen);

  if (!isOpen && isBottomSheetVisible.value.open !== false) {
    isBottomSheetVisible.value = {
      open: false,
      targetStoryPageId: null,
    };
  }
};

const pasteFromClipboard = async (storyPageId) => {
  const functionName = "pasteFromClipboard";
  console.info(componentName, functionName);

  const index = isEditMode.value
    ? editStoryStore.getPageIndexById(storyPageId)
    : newStoryStore.getPageIndexById(storyPageId);
  try {
    const link = await navigator.clipboard.readText();
    console.log(
      componentName,
      functionName,
      "index: ",
      index,
      "clipboard: ",
      link,
    );
    isEditMode.value
      ? (editStoryStore.pages[index].media.url = link)
      : (newStoryStore.pages[index].media.url = link);
  } catch (error) {
    console.error(error);
  }
};

const validateStoryPageText = (value) => {
  if (value.trim().length == 0) {
    return true;
  }

  if (value.length < 20) {
    return "Story text too short";
  }

  return true;
};

const scrollToLastStoryPage = () => {
  setTimeout(() => {
    try {
      document
        .querySelector(".container > div")
        .lastElementChild.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.warn(error);
    }
  }, 100);
};

const handleTransitionEnd = (storyPageId) => {
  const functionName = "handleTransitionEnd";
  console.info(componentName, functionName);
  try {
    // Get expanded element
    const storyPageItem = document.querySelector(`.panel-${storyPageId}`);

    // Check if element's coordinates are below window's viewport
    const rect = storyPageItem.getBoundingClientRect();
    const isOverflowing = rect.bottom > window.innerHeight;

    if (isOverflowing) {
      console.debug(
        componentName,
        functionName,
        "The element is overflowing the viewport.",
      );
      // Handle the overflow case here
      storyPageItem.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log(
        componentName,
        functionName,
        "The element is within the viewport.",
      );
    }
  } catch (error) {
    console.warn(componentName, functionName, "Error:", error);
  }
};

const extractSharedStatusFromUrl = () => {
  const functionName = "extractSharedStatusFromUrl";
  console.info(componentName, functionName);

  const fullUrl = window.location;
  console.debug(componentName, functionName, "fullUrl:", fullUrl);

  const url = new URL(fullUrl);
  const pathname = url.pathname;
  console.debug(componentName, functionName, "pathname:", pathname);

  const segments = pathname.split("/").filter((segment) => segment !== "");

  let sharedStatus;
  try {
    sharedStatus = Number(segments[2]);
  } catch (error) {
    console.error(componentName, functionName, "sharedStatus not a number");
    sharedStatus = 0;
  }

  console.debug(componentName, functionName, `sharedStatus: ${sharedStatus}`);

  if ([0, 1, 2].includes(sharedStatus)) {
    if (sharedStatus == 2 && friendStore.acceptedFriendRequest == null) {
      console.debug(
        componentName,
        functionName,
        "trying to privately share without a friend; Defaulting to private",
      );
      sharedStatus = 0; // Default to private story
    } else {
      console.debug(
        componentName,
        functionName,
        `using number '${sharedStatus}' provided in URL for sharedStatus`,
      );
      return sharedStatus;
    }
  } else {
    sharedStatus = 0; // Default to private story
    console.debug(
      componentName,
      functionName,
      `defaulting to number '${sharedStatus}' for sharedStatus`,
    );
    return sharedStatus;
  }
};

const storyPagesLoaded = computed(() => {
  return isEditMode.value
    ? editStoryStore.pages.length == 0
    : newStoryStore.pages.length == 0;
});

const storyPagesCount = computed(() => {
  const functionName = "storyPagesCount";
  console.info(componentName, functionName);

  return isEditMode.value ? editStoryStore.pages : newStoryStore.pages;
});

const handleStoryPageDragNDrop = computed(() => {
  return isEditMode.value
    ? editStoryStore.updateStoryPageOrderAfterDragNDrop
    : newStoryStore.updateStoryPageOrderAfterDragNDrop;
});

const isStoryPostable = computed(() => {
  if (isEditMode.value) {
    return editStoryStore.isStoryEdited && editStoryStore.validStory;
  } else {
    return newStoryStore.pages.some(
      (storyPage) => storyPage.media.valid && storyPage.text.length >= 20,
    );
  }
});

onMounted(async () => {
  if (isEditMode.value) {
    const storyId = route.params.postId;
    console.debug(componentName, "storyId in URL:", storyId);
    // To-be edited story's ID in URL
    if (myStoriesStore.getStoryIndexById(storyId) != -1) {
      console.debug(componentName, "in edit-mode");
      editStoryStore.populateStoryToBeEdited(storyId);
      editStoryStore.checkStoryValidity();
    } else {
      // Invalid storyId; Not existing or user's own story
      snackBarStore.displayNotification({
        message: "Invalid story",
        color: "error",
        timeout: 2250,
      });
      router.replace("/");
    }
  } else {
    newStoryStore.createStory(extractSharedStatusFromUrl());
    newStoryStore.storySharedStatusListener();
    newStoryStore.checkStoryValidity();
  }

  dimenStore.calculateIsMobile();
  await dimenStore.calculateContainerHeight(true, false);

  window.addEventListener("resize", async () => {
    await dimenStore.calculateContainerHeight(true, false);
  });
});

onUnmounted(() => {
  if (isEditMode.value) {
    editStoryStore.reset();
  } else {
    newStoryStore.deleteStory();
  }

  window.removeEventListener("resize", async () => {
    await dimenStore.calculateContainerHeight(true, false);
  });
});
</script>

<style scope>
p br::after {
  content: "\A"; /* Using a generated content with '\A' for a newline */
  white-space: pre; /* Preserve whitespace to ensure the newline is rendered */
}

.create-story-pages-list {
  width: 500px;
}

.v-card {
  text-align: left;
}

.v-card-item__content {
  padding-top: 10px;
}

.v-input {
  margin-bottom: 10px;
}

.panel-header {
  background-color: #f0f0f0;
  border: none;
  color: #333;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  width: 100%;
  text-align: left;
}

.panel-content {
  display: none;
  padding: 10px;
}
</style>
