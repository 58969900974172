import { ref } from "vue";
import { defineStore } from "pinia";

const storeName = "loadingScreenStore";

export const useLoadingScreenStore = defineStore({
  id: storeName,
  state: () => ({
    isLoading: ref(null),
  }),
  actions: {
    onInit() {
      console.info(storeName, "onInit");
      if (!this.isLoading) {
        this.isLoading = true;
      }
    },
  },
});
