import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import { useFriendStore } from "./friendStore";
import { useMyStoriesStore } from "./myStoriesStore";
import { useSnackBarStore } from "./snackBarStore";
import updateStory from "@/utils/story/updateStory";
import { v4 as uuidv4 } from "uuid";

import { ref, watch } from "vue";

const storeName = "editStoryStore";

export const useEditStoryStore = defineStore({
  id: storeName,
  state: () => ({
    isStoryEdited: ref(false),
    origStory: ref(null),
    origSharedToEnum: ref(null),
    validStory: ref(false),
    isPosting: ref(false),
    sharedStatus: ref(null),
    pages: ref([]),
    notifyFriendOfChanges: ref(false), // Whether or not to send a push-notification to friend whom the story is being shared with
    storyId: ref(null),
    authStore: useAuthStore(),
    friendStore: useFriendStore(),
    myStoriesStore: useMyStoriesStore(),
    snackBarStore: useSnackBarStore(),
  }),
  actions: {
    populateStoryToBeEdited(storyId) {
      const functionName = "populateStoryToBeEdited";
      console.info(storeName, functionName);

      console.debug(storeName, functionName, "story's id:", storyId);

      this.origStory = { ...this.myStoriesStore.getStoryById(storyId) };
      this.origSharedToEnum = this.getSharedStatus(this.origStory.sharedTo);

      this.pages = structuredClone(this.origStory.pages);
      this.pages.forEach((page) => {
        page.isExpanded = false;
        page.media = { url: page.mediaUrl, valid: true };
        page.id = uuidv4();
      });

      this.storyId = this.origStory.id;
      this.sharedStatus = this.origSharedToEnum;

      console.debug(storeName, functionName, "pages:", this.pages);
    },

    // async sendStoryUpdateNotification() {
    //   const functionName = "sendStoryUpdateNotification";

    //   const apiUrl = `http${
    //     process?.env?.NODE_ENV === "production" ? "s" : ""
    //   }://${
    //     process?.env?.NODE_ENV === "production"
    //       ? "api-efmmhglqbq-uc.a.run.app/"
    //       : "localhost:5001/storyque-fee51/us-central1/api/"
    //   }notifyOfStoryEdit`;

    //   try {
    //     let response = await fetch(apiUrl, {
    //       method: "POST",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json", // Specify that you are sending JSON data
    //       },
    //       body: JSON.stringify({
    //         editorName: this.authStore.username,
    //         receiverUserId: this.friendStore.getFriendUserId(),
    //         updatedStoryId: this.storyId,
    //       }),
    //     });
    //     if (response.ok) {
    //       response = await response.json();
    //       console.debug(functionName, "response:", response);
    //       return true;
    //     } else {
    //       console.error(functionName, "response:", response);
    //       return false;
    //     }
    //   } catch (error) {
    //     console.error(functionName, "error:", error);
    //     return false;
    //   }
    // },

    updateStoryPageOrderAfterDragNDrop(event) {
      const functionName = "updateStoryPageOrderAfterDragNDrop";
      console.info(storeName, functionName);
      console.info(storeName, functionName, "event: ", event);

      if (event.oldIndex === -1 || event.newIndex === -1) {
        console.error(
          storeName,
          functionName,
          "Invalid page index after drag-n-drop",
        );
        return;
      }

      console.debug(
        storeName,
        functionName,
        "oldIndex: ",
        event.oldIndex,
        ", newIndex: ",
        event.newIndex,
      );

      const pageObj = this.pages[event.oldIndex];

      // Remove the object from its current position
      this.pages.splice(event.oldIndex, 1);

      this.pages.splice(event.newIndex, 0, pageObj);
    },

    getPageIndexById(pageId) {
      return this.pages.findIndex((storyPage) => storyPage.id === pageId);
    },

    toggleStoryPageExpanded(id) {
      const index = this.getPageIndexById(id);
      this.pages[index].isExpanded = !this.pages[index].isExpanded;
    },

    setUrlValidity(pageId, validStatus) {
      const functionName = "setUrlValidity";
      console.info(storeName, functionName);
      console.debug(
        storeName,
        functionName,
        "pageId: ",
        pageId,
        ", validStatus: ",
        validStatus,
      );
      const index = this.getPageIndexById(pageId);
      console.debug(storeName, functionName, "index: ", index);
      if (index == -1) {
        console.error(
          storeName,
          functionName,
          "Invalid index for story page: ",
          index,
        );
        return;
      }
      this.pages[index].media.valid = validStatus;

      console.debug(storeName, functionName, "validStatus: ", validStatus);
      if (!validStatus) {
        this.pages[index].media.url = "";
        this.snackBarStore.displayNotification({
          message: "Unable to load media source",
          color: "error",
          timeout: 2500,
        });
      }
    },

    checkStoryValidity() {
      const functionName = "checkStoryValidity";
      console.info(storeName, functionName);

      watch(
        [() => this.pages, () => this.sharedStatus],
        ([editedStoryPages, editedSharedStatus]) => {
          console.debug(
            storeName,
            functionName,
            "edited story:",
            editedStoryPages,
            "edited shared status:",
            editedSharedStatus,
          );

          // Check for valid media URLs and text lengths
          for (let i = 0; i < editedStoryPages.length; i++) {
            if (
              editedStoryPages[i].media.valid == false ||
              editedStoryPages[i].text.length < 20
            ) {
              this.validStory = false;
            } else {
              this.validStory = true;
            }
          }

          let isStoryEdited = false;

          // Check sharedStatus
          if (editedSharedStatus != this.origSharedToEnum) {
            console.debug(
              storeName,
              functionName,
              `shared status edited from '${this.origSharedToEnum}' to '${editedSharedStatus}'`,
            );
            isStoryEdited = true;
          }

          if (isStoryEdited) {
            this.isStoryEdited = isStoryEdited;
            return;
          }

          for (let i = 0; i < editedStoryPages.length; i++) {
            // Check for updated text in edited story page
            if (editedStoryPages[i].text != this.origStory.pages[i].text) {
              console.debug(storeName, functionName, "story text updated");
              isStoryEdited = true;
              break;
            }

            // Check for updated story media URL in edited story page
            if (
              editedStoryPages[i].media.url != this.origStory.pages[i].mediaUrl
            ) {
              console.debug(storeName, functionName, "story media URL updated");
              isStoryEdited = true;
              break;
            }
          }

          this.isStoryEdited = isStoryEdited;
        },
        { deep: true },
      );
    },

    getSharedStatus(sharedTo) {
      const functionName = "getSharedStatus";
      console.info(storeName, functionName);

      const friendUserId = this.friendStore.getFriendUserId();

      if (sharedTo.includes("")) {
        return 1; // story is publicly shared
      } else if (sharedTo.length == 1 && sharedTo[0] == this.authStore.userId) {
        return 0; // private
      } else if (
        sharedTo.length == 2 &&
        sharedTo[0] == this.authStore.userId &&
        sharedTo[1] == friendUserId
      ) {
        return 2; // privately shared
      }
    },

    getSharedStatusArray() {
      switch (this.sharedStatus) {
        case 0:
          return [this.authStore.userId];
        case 1:
          return [""];
        case 2:
          return [this.authStore.userId, this.friendStore.getFriendUserId()];
        default:
          return [this.authStore.userId];
      }
    },

    async updateStory() {
      const functionName = "updateStory";
      console.info(storeName, functionName);

      this.isPosting = true;

      const updatedPages = structuredClone(this.pages);
      console.debug(
        storeName,
        functionName,
        "pages:",
        JSON.stringify(updatedPages),
      );

      updatedPages.forEach((storyPage) => {
        storyPage.mediaUrl = storyPage.media.url;
        delete storyPage.isExpanded;
        delete storyPage.media;
      });

      const storyUpdated = await updateStory(
        this.origStory.docId,
        this.getSharedStatusArray(),
        structuredClone(updatedPages),
      );

      console.debug(storeName, functionName, "storyUpdated:", storyUpdated);

      this.isStoryEdited = false;
      this.isPosting = false;

      if (storyUpdated) {
        this.snackBarStore.displayNotification({
          message: "Story updated",
          color: "success",
          timeout: 2000,
        });
      } else {
        this.snackBarStore.displayNotification({
          message: "Failed to update story",
          color: "error",
          timeout: 2250,
        });
      }
    },

    reset() {
      const functionName = "reset";
      console.info(storeName, functionName);

      this.validStory = false;
      this.isPosting = false;
      this.sharedStatus = null;
      this.originalStory = [];
      this.pages = [];
      this.notifyFriendOfChanges = false;
      this.storyId = null;
    },
  },
});
