import { useEditStoryStore } from "@/stores/editStoryStore";
import { useNewStoryStore } from "@/stores/newStoryStore";
import { useDialogStore } from "@/stores/dialogStore";

export const tabs = [
  {
    value: "StoryCreator",
    name: "create",
    route: "/posts/create",
  },
  {
    value: "Profile",
    name: "profile",
    route: "/profile",
  },
  {
    value: "PrivateSharedPosts",
    name: "storiesSharedToYou",
    route: "/privateSharedPosts",
  },
];

const functionName = "handleRouting";

export const handleRouting = (nextTabRoute, router, previousTab, tabIndex) => {
  console.info(functionName);

  const newStoryStore = useNewStoryStore();
  const editStoryStore = useEditStoryStore();
  const dialogStore = useDialogStore();

  const currentRoute = router.currentRoute.value;
  console.debug(functionName, "currentRoute: ", currentRoute);

  if (
    (currentRoute.name == "storyCreator" && newStoryStore.pendingChanges) ||
    (currentRoute.name == "storyEditor" && editStoryStore.isStoryEdited)
  ) {
    console.debug(functionName, "pending changes detected");
    previousTab = tabIndex;
    dialogStore.updateDiscardChangesDialog(true);
  } else {
    router.push(nextTabRoute);
  }
};
