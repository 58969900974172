import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getMessaging } from "firebase/messaging";

const fileName = "firebase.js";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);

const getFirestoreInstance = () => {
  if (
    window?.location?.hostname === "localhost" ||
    window?.location?.hostname === "www.storyque.app"
  ) {
    return "";
  } else if (window?.location?.hostname === "www.storyque.xyz") {
    return "storyque-dev";
  }
};

const firestoreInstanceName = getFirestoreInstance();

// Export Firebase services
export const auth = getAuth();
export const firestore = getFirestore(firestoreInstanceName);
export const functions = getFunctions();
export const messaging = getMessaging();

console.debug(fileName, "process.env.NODE_ENV: ", process.env.NODE_ENV);

const automatedTesting = window.automatedTesting ?? false;
console.debug(fileName, "automatedTesting:", automatedTesting);

const firestoreEmulatorPort = automatedTesting ? 8083 : 8080;
console.debug(fileName, "firestorEmulatorPort:", firestoreEmulatorPort);

const authEmulatorPort = automatedTesting ? 9100 : 9099;
console.debug(fileName, "authEmulatorPort:", authEmulatorPort);

const functionsEmulatorPort = automatedTesting ? 5002 : 5001;
console.debug(fileName, "functionsEmulatorPort:", functionsEmulatorPort);

// Use emulators during development
if (
  process.env.NODE_ENV === "development" ||
  window?.location?.hostname === "localhost"
) {
  console.debug(fileName, "using Firebase Emulator Suite");
  connectFirestoreEmulator(firestore, "localhost", firestoreEmulatorPort);
  connectAuthEmulator(auth, `http://localhost:${authEmulatorPort}`, {
    disableWarnings: true,
  });
  connectFunctionsEmulator(functions, "localhost", functionsEmulatorPort);
} else {
  console.debug(
    fileName,
    `using '${firestoreInstanceName}' Firestore instance`,
  );
}
