<template>
  <v-app-bar app :elevation="2" rounded density="compact">
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click="goBack"
        ><v-icon>mdi-arrow-left</v-icon></v-app-bar-nav-icon
      >
      <v-app-bar-title style="font-weight: bold">Storyque</v-app-bar-title>
    </template>
  </v-app-bar>
  <div
    :style="{
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      height: `${windowHeight - vTopNavHeight}px`,
    }"
  >
    <qrcode-vue :value="qrValue" :size="200" level="H" />
    <div style="font-weight: bold">
      <p style="margin-top: 16px; margin-bottom: 16px">OR</p>
    </div>
    <div>
      <v-btn
        variant="tonal"
        @click="triggerSnackbar"
        color="primary"
        :disabled="isProcessingCreatingLink"
      >
        <v-icon
          v-if="!isProcessingCreatingLink"
          style="margin-right: 10px"
          icon="mdi-link-variant"
        ></v-icon>
        {{ isProcessingCreatingLink ? "" : "Copy to clipboard" }}
        <v-progress-circular
          v-if="isProcessingCreatingLink"
          indeterminate
          size="20"
          width="3"
        ></v-progress-circular>
      </v-btn>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { v4 as uuidv4 } from "uuid";
import { onMounted, ref } from "vue";
import useClipboard from "vue-clipboard3";
import { useRouter } from "vue-router";
import { useSnackBarStore } from "../stores/snackBarStore";
import { useDimenStore } from "@/stores/dimenStore";
import createDeviceLinkCode from "../utils/linkDevices/createDeviceLinkCode";

const componentName = "LinkDevicesCreateView";

export default {
  name: componentName,
  components: { QrcodeVue },
  setup() {
    const windowHeight = ref(window.innerHeight);
    const dimenStore = useDimenStore();
    const snackBarStore = useSnackBarStore();
    const router = useRouter();

    // 3YfeLRLJ -->
    const isProcessingCreatingLink = ref(false);
    // <--

    const { toClipboard } = useClipboard();

    const code = uuidv4();

    const qrValue = ref(
      process.env.NODE_END === "development" ||
        window.location.hostname !== "www.storyque.app"
        ? `http://localhost:8081/linkDevices/verify/${code}`
        : `https://www.storyque.app/linkDevices/verify/${code}`,
    );

    const goBack = () => {
      router.go(-1); // Navigates back by one step in history
    };

    const triggerSnackbar = async () => {
      const functionName = "triggerSnackbar";
      console.info(componentName, functionName);

      isProcessingCreatingLink.value = true;

      try {
        await toClipboard(qrValue.value);
        snackBarStore.displayNotification({
          message: "Link copied",
          color: "success",
        });
        isProcessingCreatingLink.value = false;
      } catch (error) {
        isProcessingCreatingLink.value = false;
        console.error(componentName, functionName, error);

        snackBarStore.displayNotification({
          message: "Failed to copy link",
          color: "error",
        });
      }
    };

    onMounted(async () => {
      /**
       * Write UUIDv4 to Firestore for later verification
       */
      const codeCreated = await createDeviceLinkCode(code);

      console.debug(
        "LinkDevicesCreateView",
        codeCreated === true
          ? `Code saved successfully: ${code}`
          : "Failed to save code",
      );

      if (codeCreated === false) {
        snackBarStore.displayNotification({
          message: "Error occurred when trying to create working link",
          color: "error",
        });
      }
    });

    return {
      goBack,
      triggerSnackbar,
      snackBarStore,
      qrValue,
      vTopNavHeight: dimenStore.vTopNavHeight,
      windowHeight,
      // 3YfeLRLJ -->
      isProcessingCreatingLink,
      // <--
    };
  },
};
</script>

<style scope>
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
