import { firestore } from "@/firebase";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { useAuthStore } from "@/stores/authStore";

export default async function fetchStoryData(postId) {
  const functionName = "fetchStoryData";
  console.info(functionName);

  const authStore = useAuthStore();
  console.debug(functionName, "userId:", authStore.userId, "postId:", postId);

  try {
    const q = query(
      collection(firestore, "stories"),
      where("id", "==", postId),
      where("sharedTo", "array-contains-any", ["", authStore.userId]),
      limit(1),
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.size == 0) {
      console.error(functionName, "No story found with postId:", postId);
      return null;
    }

    const doc = querySnapshot.docs[0];
    let docData = doc.data();
    console.debug(functionName, "docData:", JSON.stringify(docData));
    return docData;
  } catch (error) {
    // Handle any potential errors
    console.error(functionName, "Error fetching document:", error);
  }
}

if (window.automatedTesting && typeof window !== "undefined") {
  window.originalFetchStoryData = fetchStoryData;
}
