import { firestore } from "@/firebase"; // Import your Firebase configuration
import { collection, getDocs, query, where } from "firebase/firestore";

export default async function checkIfUsernameAvailable(_username) {
  console.log("checkIfUsernameAvailable");
  const username = _username.value;
  console.debug("checkIfUsernameAvailable", "username: ", username);
  try {
    const q = query(
      collection(firestore, "users"),
      where("username", "==", username),
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length === 0) {
      // Document with specified key and value exists
      console.debug("Username available");
      return true;
    } else {
      // Document with specified key and value does not exist
      console.debug("Username unavailable");
      // snackbarVisible = true;
      return false;
    }
  } catch (error) {
    console.error("checkIfUsernameAvailable", error);
  }
}
